<template>
  <div style="margin: 20px">
    <el-button style="margin-bottom: 10px" @click="create">Создать</el-button>
    <v-table :fetch="fetchData" name="symbols" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getSymbolList } from '@/api/api'
export default {
  components: {
    VTable
  },
  data () {
    return {
      units: []
    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'required',
          filter (value) {
            return value ? 'Да' : 'Нет'
          },
          width: '130px',
          items: [
            {
              id: 0,
              title: 'Нет'
            },
            {
              id: 1,
              title: 'Да'
            }
          ]
        },
        {
          field: 'code',
          width: '100px'
        },
        {
          field: 'designatio',
          width: '115px'
        },
        {
          field: 'type_name',
          width: '110px'
        },
        {
          field: 'min',
          width: '100px'
        },
        {
          field: 'max',
          width: '100px'
        }
      ]
    }
  },
  methods: {
    fetchData: (...args) => getSymbolList(...args),
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
